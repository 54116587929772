<template>
  <div :class="preview ? 'preview-container' : 'player-container'">
    <div :style="playing ? '' : 'display: none;'">
      <vue-plyr
        ref="plyr"
        :options="preview ? previewOptions : options"
        :style="{'display': test ? 'inherit' : 'none'}"
      >
        <video
          ref="video"
          playsinline
          autoplay
        >
          <source
            v-if="isMp4"
            :src="urlSrc"
            type="video/mp4"
          >
          <source
            v-if="!isMp4"
            :src="urlSrc"
            type="application/x-mpegURL"
          >
        </video>
      </vue-plyr>
      <v-btn
        v-if="!preview"
        @click="activerAudio"
      >
        <v-icon left>
          mdi-volume-high
        </v-icon>
        Activer l'audio
      </v-btn>
    </div>
    <div v-if="!playing">
      <v-card
        :height="preview ? '100px' : '480px'"
        color="#3e3e3d"
        dark
      >
        <v-container
          class="fill-height"
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-card-title
              v-if="!preview"
              class="headline"
            >
              La conférence débutera sous peu!
            </v-card-title>
            <v-card-text
              v-if="!preview"
              class="justify-center text-center"
            >
              <v-icon style="font-size: 50px">
                mdi-video
              </v-icon>
            </v-card-text>
            <v-card-text
              v-if="preview"
              class="justify-center text-center"
            >
              Débutera sous peu
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
  import Hls from 'hls.js'

  const config = {
    liveSyncDuration: 10,
    liveBackBufferLength: 18,
    nudgeMaxRetry: 10,
  }

  export default {
    name: 'HlsPlayer',
    props: {
      urlPresentation: String,
      preview: Boolean,
    },
    data () {
      return {
        hls: null,
        quickfixApple: false,
        selectionQualite: null,
        isMp4: false,
        test: true,
        urlSrc: null,
        player: null,
        previewOptions: {
          controls: [],
          muted: true,
        },
        options: {
          // controls: [
          //   'play-large', 'play', 'progress', 'mute', 'volume', 'captions', 'settings', 'fullscreen',
          // ],
          quality: {
            default: '720',
            options: ['720', '480', '360', '240'],
            forced: true,
            onChange: (quality) => {
              console.log('Allo je change de QUALITÉ', this)
              if (this.hls) {
                this.hls.levels.forEach((level, levelIndex) => {
                  // const val1 = JSON.stringify(level.height)
                  // const val2 = JSON.stringify(quality)
                  const conv = parseInt(quality)
                  // const val3 = JSON.stringify(conv)
                  // console.log(quality, level.height, levelIndex, val1, val2, val3)
                  if (level.height === conv) {
                    console.log('Le bon niveau!', levelIndex)
                    this.hls.currentLevel = levelIndex
                  }
                })
              } else if (this.quickfixApple) {
                this.changeQualityForApple(quality)
              }
            },
          },
        },
        playing: true,
        isChromeIpad: undefined,
      }
    },
    conputed: {
      player () {
        return this.$refs.plyr.player
      },
    },
    created () {
      this.urlSrc = this.urlPresentation
      this.selectionQualite = '720'

      if (this.urlPresentation.indexOf('.mp4') >= 0) {
        this.isMp4 = true
      }
    },
    mounted () {
      this.init()
      // console.log(`is preview ${this.preview}`)
      // vm.hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      // const availableQualities = vm.hls.levels.map((l) => l.height)

      // options.quality = {
      //   default: availableQualities[0],
      //   options: availableQualities,
      //   forced: true,
      //   onChange: (e) => updateQuality(e),
      // }

      // vm.$refs.plyr.player = new Plyr(vm.$refs.video, options)
      // })
    },
    beforeDestroy () {
      // console.log('Kill HLS player before navigating')
      if (this.hls) {
        this.hls.destroy()
      }
    },
    methods: {
      init () {
        if (this.preview) {
          this.$refs.video.muted = 'muted'
        }

        // this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
        this.isChromeIpad = /iPad/.test(navigator.userAgent) || /iPhone/.test(navigator.userAgent)
        // this.userAgent = navigator.userAgent + ' -- ' + navigator.vendor

        if (this.isMp4) {
          return
        }

        if (Hls.isSupported()) {
          console.log('Hls is supported!!!')
          this.hls = new Hls(config)
          this.hls.loadSource(this.urlPresentation)

          this.hls.on(Hls.Events.ERROR, this.handleError)

          this.hls.on(Hls.Events.MANIFEST_PARSED, this.handleManifestParsed)

          const keys = Object.keys(Hls.Events)
          keys.forEach(key => {
            this.hls.on(Hls.Events[key], (event, data) => {
              // console.log(event, data)
            })
          })

          this.hls.attachMedia(this.$refs.video)
        } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
          // console.log('Hls is !!!NOT!!! supported')
          this.quickfixApple = true
          this.urlSrc = this.urlPresentation
        }
      },
      changeQualityForApple (quality) {
        if (this.selectionQualite === quality) {
          return
        }
        // this.selectionQualite = quality
        this.$set(this, 'selectionQualite', quality)

        const suffixes = {
          720: '_720p2628kbs/index.m3u8',
          480: '_480p1128kbs/index.m3u8',
          360: '_360p878kbs/index.m3u8',
          240: '_240p528kbs/index.m3u8',
        }

        // const fichier = suffixes[this.selectionQualite]
        // console.log(fichier)

        // const racine = this.urlPresentation.substring(0, this.urlPresentation.lastIndexOf('/'))
        // const split = this.urlPresentation.split('/')
        // const nomFichier = split[split.length - 1]
        // console.log(racine, nomFichier)

        // const cle = nomFichier.replace('.m3u8', '')
        const url = this.urlPresentation.replace('.m3u8', '') + suffixes[this.selectionQualite]
        // console.log('Url pour quickfix apple', url)

        this.urlSrc = url
        this.$refs.video.src = url
        this.test = false
        setTimeout(() => {
          // console.log('Allo! JE refresh le lecteur')
          this.test = true
          this.$forceUpdate()
        }, 500)
      },
      handleManifestParsed (event, data) {
        this.playing = true
      },
      handleError (event, data) {
        console.error(event, data)
        // this.playing = false
        // this.hls.destroy()
        // setTimeout(this.init, 5000)
      },
      activerAudio () {
        console.log("Activer l'audio")
        this.$refs.video.volume = 1
        this.$refs.video.muted = false
      },
    },
  }
</script>

<style src='plyr/dist/plyr.css'></style>

<style scoped>
.preview-container {
  width: 200px;
}
</style>
